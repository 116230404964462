import axios from 'axios'

const client = axios.create({
  baseURL: process.env.API_URL,
  timeout: 10000,
  withCredentials: true,
  // withXSRFToken: true,
  headers: {
    Accept: 'application/json',
    'Content-Type': 'application/json'
  }
})

export default client
