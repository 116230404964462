import { createTheme } from '@mantine/core'

export const theme = createTheme({
  breakpoints: {
    xs: '30em',
    sm: '48em',
    md: '64em',
    lg: '74em',
    xl: '90em'
  },
  colors: {
    green: [
      '#e6fff5',
      '#d0ffeb',
      '#9ffed6',
      '#6bfebf',
      '#46feac',
      '#32fea0',
      '#00ca75', // primary
      '#07bf72',
      '#06b46b',
      '#03a25b'
    ]
  },
  primaryColor: 'green'
})
