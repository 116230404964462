import type React from 'react'
import { useEffect } from 'react'
import useCustomI18n from './useCutomI18n'

type I18nLanguageRedirectProviderProps = {
  children: React.ReactNode
}

const I18nLanguageRedirectProvider: React.FC<
  I18nLanguageRedirectProviderProps
> = ({ children }) => {
  const { currentlanguage, userRequiredLanguage, changeCurrentLanguage } =
    useCustomI18n()

  // 言語情報によって、リダイレクトを行う副作用
  useEffect(() => {
    if (currentlanguage !== userRequiredLanguage) {
      changeCurrentLanguage(userRequiredLanguage as 'en' | 'ja')
    }
  }, [changeCurrentLanguage, currentlanguage, userRequiredLanguage])

  return children
}

export default I18nLanguageRedirectProvider
