import { type AxiosError, type AxiosRequestConfig } from 'axios'
import client from './client'
import { type NoContent } from '@/types'

export const get = async (url: string) =>
  await client.get(url).then(res => res.data.data)

export const paginationGet = async (url: string) =>
  await client.get(url).then(res => res.data)

type FetchResponse<T> =
  | { data: T; isSuccess: true; error?: never }
  | { data?: never; isSuccess: false; error: AxiosError<{ message: string }> }

export const onceGet = async <T>(url: string, config?: AxiosRequestConfig) => {
  try {
    const data = await client.get(url, config).then(res => res.data.data)
    const response: FetchResponse<T> = {
      data,
      isSuccess: true
    }
    return response
  } catch (error) {
    const response: FetchResponse<T> = {
      isSuccess: false,
      error: error as AxiosError<{ message: string }>
    }
    return response
  }
}

export const post = async <T>(
  url: string,
  body?: any,
  config?: AxiosRequestConfig
) => {
  try {
    const data = await client.post(url, body, config).then(res => res.data.data)
    const response: FetchResponse<T> = {
      data,
      isSuccess: true
    }
    return response
  } catch (error) {
    const response: FetchResponse<T> = {
      isSuccess: false,
      error: error as AxiosError<{ message: string }>
    }
    return response
  }
}

export const put = async <T>(
  url: string,
  body?: any,
  config?: AxiosRequestConfig
) => {
  try {
    const data = await client.put(url, body, config).then(res => res.data.data)
    const response: FetchResponse<T> = {
      data,
      isSuccess: true
    }
    return response
  } catch (error) {
    const response: FetchResponse<T> = {
      isSuccess: false,
      error: error as AxiosError<{ message: string }>
    }
    return response
  }
}

export const patch = async <T>(
  url: string,
  body?: any,
  config?: AxiosRequestConfig
) => {
  try {
    const data = await client
      .patch(url, body, config)
      .then(res => res.data.data)
    const response: FetchResponse<T> = {
      data,
      isSuccess: true
    }
    return response
  } catch (error) {
    const response: FetchResponse<T> = {
      isSuccess: false,
      error: error as AxiosError<{ message: string }>
    }
    return response
  }
}

export const destroy = async (
  url: string,
  body?: any,
  config?: Omit<AxiosRequestConfig, 'data'>
) => {
  try {
    const data = await client.delete(url, {
      ...config,
      data: body
    }).then(res => res.data.data)
    const response: FetchResponse<NoContent> = {
      data,
      isSuccess: true
    }
    return response
  } catch (error) {
    return { isSuccess: false, error: error as AxiosError<{ message: string }> }
  }
}
