import { type SWRPaginationData } from '@/types'
import useSWRInfinite, { type SWRInfiniteConfiguration } from 'swr/infinite'
import { paginationGet } from './fetcher'
import { useCallback } from 'react'

type SWRCursorPaginateConfiguration = {
  params?: Record<string, string | boolean | number | null>
} & SWRInfiniteConfiguration

/**
 * レコードからURLパラメーターの文字列を生成する関数
 * @param params URLパラメーターのkey-valueレコード
 * @param first URLパラメーターを最初として処理するかどうか
 * @returns 
 */
const createParamsText = (
  params: Record<string, string | boolean | number | null> | undefined,
  first = true
) => {
  if (!params) return ''
  const result = Object.entries(params)
    .map(([key, value]) => `${key}=${value}`)
    .join('&')
  return first ? `?${result}` : `&${result}`
}

/**
 * laravelのカーソルページネーションに対応したSWRのカスタムフック
 * @param url 情報を取得するURL
 * @param options SWRInfiniteConfigurationにURLパラメーターレコードを追加したもの
 * @returns CursorPaginate専用メソッドと、URL情報のSWRResponse
 */
const useSWRCursorPaginate = <T>(
  url: string,
  options?: SWRCursorPaginateConfiguration
) => {
  const { params, ...swrOptions } = options ?? {}

  const getKey = (
    pageIndex: number,
    previousPageData: SWRPaginationData<T[]>
  ) => {
    // 最初のページでは、`previousPageData` がありません
    if (pageIndex === 0) return `${url}${createParamsText(params)}`

    // 最後に到達した
    if (
      (previousPageData && !previousPageData.data) ||
      previousPageData.meta.next_cursor === null
    )
      return null

    // API のエンドポイントにカーソルを追加します
    return `${url}?cursor=${
      previousPageData.meta.next_cursor
    }${createParamsText(params, false)}`
  }

  const {
    setSize,
    data: originData,
    mutate: originMutate,
    ...other
  } = useSWRInfinite<SWRPaginationData<T[]>>(getKey, paginationGet, swrOptions)

  /**
   * 次のページの取得を指示する関数
   */
  const next = useCallback(() => {
    void setSize(prevSize => prevSize + 1)
  }, [setSize])

  const data = originData?.map(data => data.data)
  const paginationMetas = originData?.map(data => ({
    links: { ...data.links },
    meta: { ...data.meta }
  }))
  const isLast = paginationMetas?.slice(-1)[0].meta.next_cursor === null

  /**
   * データを楽観的更新する関数
   * @param data 変更後のデータ
   */
  const mutate = async (data: T[][]) => {
    if (!paginationMetas) return
    const mutateDate = data.map((users, index) => {
      return {
        data: users,
        links: paginationMetas[index].links,
        meta: paginationMetas[index].meta
      }
    })
    await originMutate(mutateDate)
  }

  return { next, data, paginationMetas, isLast, mutate, originMutate, ...other }
}

export default useSWRCursorPaginate
