import { createContext } from 'react'
import { type User } from '@/types'
import { type Plan } from './types'

type AccountInfomationContextType = {
  userData: User | undefined
  isLoggined: boolean
  isAdmin: boolean
  isEndLoading: boolean
  refresh: () => Promise<void>
  checkPermission: (plan: Plan) => boolean
}
const AccountInfomationContext = createContext<AccountInfomationContextType>({
  userData: undefined,
  isLoggined: false,
  isAdmin: false,
  isEndLoading: false,
  refresh: async () => {},
  checkPermission: () => false
})
export default AccountInfomationContext
