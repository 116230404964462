import React, { type FC } from 'react'
import { useMe } from '@/api/users'
import AccountInfomationContext from './AccountInfomationContext'
import { type Plan } from './types'

type AccountInfomationProviderProps = {
  children: React.ReactNode
}

const AccountInfomationProvider: FC<AccountInfomationProviderProps> = ({
  children
}) => {
  const { data, isLoading, mutate } = useMe()

  const refresh = async () => {
    await mutate()
  }

  const checkPermission = (plan: Plan) => {
    // 開発者はVIPの権限処理を通過
    if (plan === 'vip' && data?.developper) return true
    return data?.plan === plan
  }

  return (
    <AccountInfomationContext.Provider
      value={{
        userData: data,
        isLoggined: data !== undefined,
        isAdmin: Boolean(data?.admin && data?.current_webauth),
        isEndLoading: !isLoading,
        refresh,
        checkPermission
      }}>
      {children}
    </AccountInfomationContext.Provider>
  )
}

export default AccountInfomationProvider
